// Acquire redeem master
module.exports = {
  host: "localhost",
  port: 9141,
  apiHost: {
    server       : "http://127.0.0.1/api",
    client       : "/api",
    proxyHeaders : ["SSL", "Ssl-Offloaded", "X-Forwarded-Proto", "Host", "X-Real-Ip", "X-Forwarded-For"],
  },
  cspUri: null,
  googleAnalytics: {
    sv_SE: {
      currencyCode: "SEK",
      accounts: ["UA-5572085-111"]
    }
  },
  isProduction: true
};
